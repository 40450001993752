import {useParams} from "react-router-dom";
import {isErrorWithMessage, returnErrorMessage, showErrorMessages} from "../../app/api";
import {useEffect, useRef, useState} from "react";
import {
    IEventRead,
    IValidateTicketResponse,
    useLazyReadEventQuery,
    useValidateTicketMutation
} from "../../api/event";
import {Box, Button, Card, Flex, Modal, Progress, Text} from "@mantine/core";
import QrReader from "../../components/qr-reader/QrReader";
import dayjs from "dayjs";
import {useLazyTicketStatisticsQuery} from "../../api/analytics";
import notification_sound from './scanned_sound.mp3'

const audio = new Audio(notification_sound);

const TicketValidation = () => {
    const params = useParams();
    const cameraRef = useRef<{preferredCamera: string}>();
    const [validateTicketRq] = useValidateTicketMutation();
    const [readEvent] = useLazyReadEventQuery();
    const [event, setEvent] = useState<IEventRead>()

    const [getStatistics] = useLazyTicketStatisticsQuery();

    // Result
    const [scannedResult, setScannedResult] = useState<string | undefined>("");
    const [scannedError, setScannedError] = useState<string | undefined | string[]>("");
    const [validatedResult, setValidatedResult] = useState<IValidateTicketResponse | undefined>();
    const [validatedToken, setValidatedToken] = useState<string | undefined>(undefined)
    const [seats, setSeats] = useState({total: 0, used: 0, percentage: 0})
    const [isPaused, setIsPaused] = useState<boolean>(false);
    useEffect(() => {
        if (params.id) {
            readEvent({id: Number(params.id)}).then(res => setEvent(res.data))
            checkSeats()
        }
    }, [params.id]);

    useEffect(() => {
        if (scannedResult && params.id) {
            validateSeat(scannedResult, Number(params.id));
        }

    }, [scannedResult, params.id, validatedToken])

    async function validateSeat(token: string, eventId: number) {
        try {
            if (validatedToken === token) return
            await audio.play();
            const res = await validateTicketRq({event: eventId, token}).unwrap();
            setValidatedToken(token)
            setValidatedResult(res)
            setScannedError('')
            if(cameraRef.current?.preferredCamera === 'environment') {
                setIsPaused(true)
            }
            checkSeats();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                const errors = returnErrorMessage(e.data);
                if(cameraRef.current?.preferredCamera === 'environment') {
                    setIsPaused(true)
                }
                setValidatedResult(undefined)
                setScannedError(errors)
            }
        }
    }

    async function checkSeats() {
        try {
            const statistics = await getStatistics({event: Number(params.id)}).unwrap();
            const usedSeats = statistics.filter((f: any) => f.status === "used").length;
            setSeats({
                total: statistics.length,
                used: usedSeats,
                percentage: (100 * (usedSeats)) / (statistics.length)
            })
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
        }
    }

    if (!event) return (
        <>Loading...</>
    )

    return (
        <Flex gap={30}
              direction={{base: 'column', xs: 'row', sm: "row", lg: 'row'}}
              h={'calc(100vh - 90px)'}
        >
            <Box w={{sm: '100%', lg: "calc(100% - 300px)"}} h={{sm: '90%', lg: '100%'}}>
                <QrReader
                    onScanSuccess={setScannedResult}
                    onScanFail={setScannedResult}
                    paused={isPaused}
                    cameraRef={cameraRef}
                />
            </Box>
            <Card w={{sm: '100%', xs: "50%", lg: '300px'}} px={15}
                  h={{base: 'calc(100% - 400px)', xs: "100%", md: '100%'}} shadow={'sm'}>
                <Flex direction={{base: 'column', sm: 'column', md: "column", lg: 'column'}} justify={'space-between'}
                      h={'100%'}
                      w={'100%'}>
                    <Box>
                        <Text size={"lg"} fw={'bolder'}>{event.name}</Text>
                        <Text size={"md"} pt={5}>{dayjs(event.start).format("DD MMMM YYYY HH:mm")}</Text>
                    </Box>

                    <Box>
                        {scannedError && (
                            <Flex direction={{base: 'column-reverse', lg: 'column'}}>
                                <Box w={'100%'} h={10} bg={'red.5'} style={{borderRadius: 16}}/>
                                <Text pt={10}>{scannedError}</Text>
                            </Flex>
                        )}
                        {validatedResult && (
                            <Box>
                                <Box w={'100%'} h={10} bg={'green.5'} style={{borderRadius: 16}}/>
                                <Text size="sm" mb="xs" fw={500}>
                                    Билет
                                </Text>
                                <Text size="sm" mb="xs" fw={500} mt={20}>
                                    {validatedResult?.key.split(':')[0]}, Место {validatedResult?.key.split(':')[1]},
                                    Ряд {validatedResult?.key.split(':')[2]}
                                </Text>
                                <Text size="sm" mb="xs" fw={500}>
                                    Цена {validatedResult?.amount}
                                </Text>
                            </Box>
                        )}
                    </Box>


                    <Box mt={{base: 30}}>
                        <Text size={"lg"} fw={'normal'}>Заполненность</Text>
                        <Box pt={20}>
                            <Progress value={seats.percentage} striped/>
                            <Text c={'dimmed'} size={'sm'} ta={'center'}>{seats.used} / {seats.total}</Text>
                        </Box>
                    </Box>
                </Flex>
            </Card>
            <Modal opened={Boolean(isPaused && (validatedResult || scannedError))} onClose={() => setIsPaused(false)} centered={true}>
                <Box pb={50}>
                    {scannedError && (
                        <Flex direction={{base: 'column-reverse', lg: 'column'}}>
                            <Box w={'100%'} h={10} bg={'red.5'} style={{borderRadius: 16}}/>
                            <Text pt={10}>{scannedError}</Text>
                        </Flex>
                    )}
                    {validatedResult && (
                        <Box>
                            <Box w={'100%'} h={10} bg={'green.5'} style={{borderRadius: 16}}/>
                            <Text size="sm" mb="xs" fw={500}>
                                Билет
                            </Text>
                            <Text size="sm" mb="xs" fw={500} mt={20}>
                                {validatedResult?.key.split(':')[0]}, Место {validatedResult?.key.split(':')[1]},
                                Ряд {validatedResult?.key.split(':')[2]}
                            </Text>
                            <Text size="sm" mb="xs" fw={500}>
                                Цена {validatedResult?.amount}
                            </Text>
                        </Box>
                    )}
                </Box>
                <Button onClick={() => setIsPaused(false)}>
                    Ok
                </Button>
            </Modal>
        </Flex>
    )
}
export default TicketValidation