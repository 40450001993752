import {useEffect, useRef, useState} from "react";

// Styles
import "./QrStyles.css";

// Qr Scanner
import QrScanner from "qr-scanner";
import {Scan, SwitchCameraIcon} from "lucide-react";
import {ActionIcon, Box} from "@mantine/core";

type TProps = {
    onScanSuccess: (result: string) => void;
    onScanFail: (err: string | undefined) => void;
    paused?: boolean;
    cameraRef?: any
}

const QrReader = ({onScanSuccess, onScanFail, paused, cameraRef}: TProps) => {
    const scanner = useRef<QrScanner>();
    const [activeCamera, setActiveCamera] = useState<string>('environment');
    const videoEl = useRef<HTMLVideoElement>(null);
    const qrBoxEl = useRef<HTMLDivElement>(null);
    const [qrOn, setQrOn] = useState<boolean>(true);

    // Success
    const handleScanSuccess = (result: QrScanner.ScanResult) => {
        // 🖨 Print the "result" to browser console.
        // ✅ Handle success.
        // 😎 You can do whatever you want with the scanned result.
        onScanSuccess(result?.data);
    };

    // Fail
    const handleScanFail = (err: string | Error) => {
        // 🖨 Print the "err" to browser console.
        onScanFail(undefined)
    };

    useEffect(() => {
        if (videoEl?.current && !scanner.current) {
            // 👉 Instantiate the QR Scanner
            scanner.current = new QrScanner(videoEl?.current, handleScanSuccess, {
                onDecodeError: handleScanFail,
                // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
                preferredCamera: activeCamera,
                // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
                highlightScanRegion: false,
                // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
                highlightCodeOutline: false,
                // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
                overlay: qrBoxEl?.current || undefined,
                maxScansPerSecond: 1,
            });
            cameraRef.current = {preferredCamera: activeCamera};
            // 🚀 Start QR Scanner
            scanner?.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        // 🧹 Clean up on unmount.
        // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
        return () => {
            if (!videoEl?.current) {
                scanner?.current?.stop();
            }
        };
    }, []);

    // ❌ If "camera" is not allowed in browser permissions, show an alert.
    useEffect(() => {
        if (!qrOn)
            alert(
                "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
            );
    }, [qrOn]);

    useEffect(() => {
        if (paused) {
            scanner?.current?.stop();
        } else {
            scanner?.current?.start();
        }

    }, [paused]);

    function swapCamera() {
        if (scanner.current) {
            const value = activeCamera === 'environment' ? 'user' : 'environment';
            scanner.current?.setCamera(activeCamera === 'environment' ? 'user' : 'environment')
            cameraRef.current = {preferredCamera: value};
            setActiveCamera(value)
        }

    }

    return (
        <div className="qr-reader">
            {/* QR */}
            <video ref={videoEl}></video>
            <div ref={qrBoxEl} className="qr-box">
                <Scan
                    color={'white'}
                    width={256}
                    height={256}
                    strokeWidth={"0.5px"}
                    className="qr-frame"
                />
                <Box style={{
                    position: "absolute",
                    bottom: 10,
                    right: 10
                }}>
                    <ActionIcon variant={"transparent"} onClick={swapCamera}>
                        <SwitchCameraIcon/>
                    </ActionIcon>
                </Box>
            </div>

        </div>
    );
};

export default QrReader;